import { useContext, useState } from 'react'
import { colors } from '../theme/colors'
import { IonContent, IonIcon, IonLabel, IonList, IonModal } from '@ionic/react'
import { chevronForward } from 'ionicons/icons'
import CommonHeader from '../commonComponents/CommonHeader'
import StandardContainer from '../commonComponents/StandardContainer'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router'
import ResultItem from '../appointments/ResultItem'

export default function WalkinAppointments({ walkinsList }) {
  const { businessData } = useContext(AuthContext)
  const [modalOpen, setModalOpen] = useState(false)
  const history = useHistory()
  const openModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    setModalOpen(false)
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        onClick={openModal}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: colors.orange,
          width: '95%',
          textAlign: 'center',
          margin: '1rem 0 0 0',
          borderRadius: '5px',
          padding: '0.8rem 0.5rem',
          fontSize: 13,
          fontWeight: '700',
        }}
      >
        <IonLabel style={{ fontSize: '14px' }}>
          {walkinsList && walkinsList.length} Walk-in
          {walkinsList && walkinsList.length != 1 ? 's' : ''} Waiting
        </IonLabel>
        <IonIcon slot='end' icon={chevronForward} style={{ color: 'black' }} />
      </div>

      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader title={'Walk-ins'} closeButton={true} closeModal={closeModal}></CommonHeader>
        <IonContent className='regularBackground'>
          <StandardContainer>
            <IonList inset={true} mode='ios'>
              {walkinsList?.map(row => (
                <ResultItem
                  closeModal={closeModal}
                  row={row}
                  businessId={businessData?._id}
                  key={row?._id}
                />
              ))}
            </IonList>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </div>
  )
}