import { IonAvatar, IonRouterLink, IonCol, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonMenu, IonRow, IonToolbar } from '@ionic/react'
import { useContext } from 'react'
import { AuthContext } from '../../auth'
import { useHistory } from 'react-router'
import axios from 'axios'
import CheckIsManagerOrAdmin from '../../Utils/CheckIsManagerOrAdmin'
import SwitchBusiness from './SwitchBusiness'
import AddLocationAndBusinessButtons from './AddLocationAndBusinessButtons'
import { personOutline } from 'ionicons/icons'
import StandardCenterCenter from '../StandardCenterCenter'
import { colors } from '../../theme/colors'
import { AccessControl } from '../../AccessControl'
import { Link } from 'react-router-dom'

export default function DefaultMenu() {
  const history = useHistory()
  const { userData, locationData, locationId, setLocationId, businessData, getUserData } = useContext(AuthContext)
  const setDefaultLocation = (selectedLocation) => {
    axios.defaults.headers.common['prop-Locationid'] = selectedLocation._id
    localStorage.setItem('defaultLocation', selectedLocation._id)
    setLocationId(selectedLocation._id)
    getUserData()
    history.push('/core/dashboard')
  }

  const userEntitlements = CheckIsManagerOrAdmin(userData, businessData, locationId)

  return (
    <IonMenu contentId='main-content'>
      <IonHeader style={{ paddingBottom: '0.5rem' }}>
        <IonToolbar color='white' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonRow
            style={{
              display: 'flex',
              // justifyContent: 'center',
              alignItems: 'center',
              padding: '10px',
              flexDirection: 'row',
            }}
          >
            <div style={{ padding: 7, borderRadius: 20, backgroundColor: '#d6d6d6' }}>
              <StandardCenterCenter>
                <IonIcon icon={personOutline} />
              </StandardCenterCenter>
            </div>

            <IonRow
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonLabel style={{ marginTop: '10px', fontSize: '1rem', fontWeight: 'bold', marginLeft: 10 }}>
                <h6>
                  {userData?.firstName} {userData?.lastName}
                </h6>
              </IonLabel>
              <IonLabel style={{ fontSize: '0.8rem', fontWeight: '600' }}>
                <h6>{userData?.email}</h6>
              </IonLabel>
            </IonRow>
          </IonRow>
        </IonToolbar>
      </IonHeader>
      <IonContent className='whiteBackground ion-padding' color='white' style={{ backgroundColor: 'white', color: 'black' }}>
        <IonRow
          style={{
            marginBottom: '0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <IonCol size='auto' style={{ margin: 0, padding: 0 }}>
            <IonAvatar style={{ backgroundColor: 'black', height: 36, width: 36, padding: 6 }}>
              <img alt='ClubCuts' src='/assets/logos/CLUBCUTS_WHITE.png' />
            </IonAvatar>
          </IonCol>
          <IonCol style={{ cursor: 'pointer', margin: 0, padding: 0 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link to='/business/dashboard' style={{ textDecoration: 'none', color: 'inherit' }}>
                <h6 style={{ margin: 0, marginLeft: 14 }}>{businessData?.name}</h6>
              </Link>
            </div>
          </IonCol>
          {userEntitlements?.isAdminPlus && (
            <IonCol size='auto' style={{ margin: 0, padding: 0 }}>
              <div style={{ marginTop: -14 }}>
                <SwitchBusiness />
              </div>
            </IonCol>
          )}
        </IonRow>

        {locationData &&
          locationData.length > 0 &&
          locationData.map((location) => {
            // Split the input string by spaces, extract first letters, and join them
            const locationMnemonic = location.name
              ? location.name
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase())
                  .join('')
              : ''
            return (
              <AccessControl componentName={'switch_locations_list_item'} locationId={location._id}>
                <IonItem
                  key={location._id}
                  onClick={() => setDefaultLocation(location)}
                  style={{
                    cursor: 'pointer',
                    color: locationId === location._id ? colors.primaryBlue : 'black',
                    fontWeight: '600',
                    '--ion-item-background': locationId === location._id ? colors.primaryBlue90 : '',
                  }}
                >
                  <div style={{ backgroundColor: '#d6d6d6', borderRadius: 20, height: 36, width: 36 }}>
                    <StandardCenterCenter>
                      <h6 style={{ margin: 0, marginTop: 8 }}>{locationMnemonic}</h6>
                    </StandardCenterCenter>
                  </div>
                  <IonLabel style={{ marginLeft: '0.5rem' }}>
                    <h6 style={{ fontWeight: locationId === location._id ? '700' : '500' }}>{location.name}</h6>
                  </IonLabel>
                </IonItem>
              </AccessControl>
            )
          })}

        <AddLocationAndBusinessButtons />
      </IonContent>
    </IonMenu>
  )
}
