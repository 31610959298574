import React from 'react'
import axios from 'axios'
import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonText, IonChip, IonModal, IonAlert, IonToast } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import SimpleDialog from './addNewSchedule'

export default function ModifiedSchedule({ standardSchedule, locationId, employeeId, businessId }) {
  const [open, setOpen] = React.useState(false)
  const [modifiedSchedule, setModifiedSchedule] = React.useState([])
  const [showAlert, setShowAlert] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(null)
  const [toast, setToast] = React.useState({ isOpen: false, message: '', color: '' })

  // Common handle toast function
  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const getModifiedSchedule = async () => {
    try {
      let response = await axios.get(`/employee/modifiedSchedule?employeeId=${employeeId}`)
      setModifiedSchedule(response.data)
    } catch (error) {
      console.error('Error fetching modified schedule:', error)
    }
  }

  React.useEffect(() => {
    getModifiedSchedule()
  }, [employeeId])

  const handleSave = async (data) => {
    try {
      await axios.post(`/employee/modifiedSchedule`, {
        ...{ locationId, businessId, employeeId },
        ...data,
      })
      getModifiedSchedule()
      handleClose()
      showToastMessage('Modified schedule saved successfully.')
    } catch (error) {
      console.error('Error saving modified schedule:', error)
      showToastMessage('Sorry, there was an issue saving. Try again later.', 'danger')
    }
  }

  const handleDelete = async () => {
    try {
      if (deleteId) {
        await axios.delete(`/employee/modifiedSchedule?id=${deleteId}`)
        getModifiedSchedule()
        setDeleteId(null)
        showToastMessage('Modified schedule deleted successfully.')
      }
    } catch (error) {
      console.error('Error deleting modified schedule:', error)
      showToastMessage('Looks like there was an issue deleting. Try again later.', 'danger')
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='8'>
            <IonText>
              <h5>Modified Schedule</h5>
            </IonText>
          </IonCol>
          <IonCol size='4' className='ion-text-right'>
            <IonButton color='primary' onClick={handleClickOpen} fill='clear'>
              + Add
            </IonButton>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol size='12'>
            <IonText style={{ marginBottom: 20, fontSize: 12 }}>Modify your future schedule by changing hours or marking a day off.</IonText>
          </IonCol>
        </IonRow>
        <IonGrid>
          <IonRow className='ion-align-items-center ion-justify-content-between'>
            <IonCol size='3'>
              <IonText color='medium'>DATE</IonText>
            </IonCol>
            <IonCol size='3'>
              <IonText color='medium'>OPEN</IonText>
            </IonCol>
            <IonCol size='2'>
              <IonText color='medium'>OPEN TIME</IonText>
            </IonCol>
            <IonCol size='2'>
              <IonText color='medium'>CLOSE TIME</IonText>
            </IonCol>
            <IonCol size='2'>
              <IonText color='medium'>DELETE</IonText>
            </IonCol>
          </IonRow>
          {modifiedSchedule.map((d) => (
            <IonRow key={d._id} className='ion-align-items-center ion-justify-content-between'>
              <IonCol size='3'>
                <IonText>{d.dateText}</IonText>
              </IonCol>
              <IonCol size='3'>
                <IonChip color={d.enabled ? 'primary' : 'medium'}>
                  <IonText>{d.enabled ? 'Working' : 'Day Off'}</IonText>
                </IonChip>
              </IonCol>
              <IonCol size='2'>
                <IonText>{d.enabled ? d.startTime : <div style={{ width: 90 }} />}</IonText>
              </IonCol>
              <IonCol size='2'>
                <IonText>{d.enabled ? d.endTime : <div style={{ width: 90 }} />}</IonText>
              </IonCol>
              <IonCol style={{ display: 'flex' }} size='2' className='ion-text-right'>
                <IonButton
                  fill='clear'
                  color='danger'
                  onClick={() => {
                    setDeleteId(d._id)
                    setShowAlert(true)
                  }}
                >
                  <IonIcon icon={trashOutline} />
                </IonButton>
              </IonCol>
            </IonRow>
          ))}
        </IonGrid>
      </IonGrid>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <SimpleDialog open={open} onClose={handleClose} standardSchedule={standardSchedule} handleSave={handleSave} />
      </IonModal>
      <IonAlert
        isOpen={showAlert}
        onDidDismiss={() => setShowAlert(false)}
        header={'Delete'}
        message={'Are you sure you want to delete this modified schedule?'}
        buttons={[
          {
            text: 'Cancel',
            role: 'cancel',
          },
          {
            text: 'Delete',
            handler: handleDelete,
          },
        ]}
      />
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
