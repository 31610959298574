import React from 'react'
import axios from 'axios'
import { IonButton, IonIcon, IonGrid, IonRow, IonCol, IonModal, IonText, IonToast } from '@ionic/react'
import { trashOutline } from 'ionicons/icons'
import AddBreak from './addBreak'

export default function Breaks({ standardSchedule, locationId, employeeId, businessId }) {
  const [open, setOpen] = React.useState(false)
  const [breaks, setBreaks] = React.useState([])
  const [showToast, setShowToast] = React.useState(false)
  const [toastState, setToastState] = React.useState({ message: '', color: '' })

  const getBreaks = async () => {
    try {
      let response = await axios.get(`/employee/break?employeeId=${employeeId}`)
      setBreaks(response.data)
    } catch (error) {
      console.error('Error fetching breaks:', error)
    }
  }

  React.useEffect(() => {
    getBreaks()
  }, [employeeId])

  const handleSave = async (data) => {
    const breakInput = {
      ...{ locationId, businessId, employeeId },
      ...data,
      repeat: data.repeat,
    }

    try {
      await axios.post(`/employee/break`, breakInput)
      getBreaks()
      handleClose()
      setToastState({ message: 'Break added successfully!', color: 'success' })
      setShowToast(true)
    } catch (error) {
      console.error('Error saving break:', error)
      setToastState({ message: 'Error saving break. Please try again.', color: 'danger' })
      setShowToast(true)
    }
  }

  const handleDelete = async (id) => {
    try {
      if (window.confirm('Are you sure you want to delete the break?')) {
        await axios.delete(`/employee/break?id=${id}`)
        getBreaks()
        setToastState({ message: 'Break deleted successfully!', color: 'success' })
        setShowToast(true)
      }
    } catch (error) {
      console.error('Error deleting break:', error)
      setToastState({ message: 'Error deleting break. Please try again.', color: 'danger' })
      setShowToast(true)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='8'>
            <IonText>
              <h5>Breaks Schedule</h5>
            </IonText>
          </IonCol>
          <IonCol size='4' className='ion-text-right'>
            <IonButton fill='clear' color='primary' onClick={handleClickOpen}>
              + Add
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <IonGrid>
        <IonRow className='ion-align-items-center ion-justify-content-between'>
          <IonCol size='3'>
            <IonText color='medium'>DATE</IonText>
          </IonCol>
          <IonCol size='3'>
            <IonText color='medium'>START TIME</IonText>
          </IonCol>
          <IonCol size='3'>
            <IonText color='medium'>END TIME</IonText>
          </IonCol>
          <IonCol size='3'>
            <IonText color='medium'>DELETE</IonText>
          </IonCol>
        </IonRow>
        {breaks.map((b) => (
          <IonRow key={b._id} className='ion-align-items-center ion-justify-content-between'>
            <IonCol size='3'>
              <IonText>{b.dateText}</IonText>
            </IonCol>
            <IonCol size='3'>
              <IonText>{b.startTime}</IonText>
            </IonCol>
            <IonCol size='3'>
              <IonText>{b.endTime}</IonText>
            </IonCol>
            <IonCol style={{ display: 'flex' }} size='3' className='ion-text-right'>
              <IonButton fill='clear' color='danger' onClick={() => handleDelete(b._id)}>
                <IonIcon icon={trashOutline} />
              </IonButton>
            </IonCol>
          </IonRow>
        ))}
      </IonGrid>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <AddBreak open={open} onClose={handleClose} standardSchedule={standardSchedule} handleSave={handleSave} />
      </IonModal>
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastState.message}
        duration={2000}
        color={toastState.color}
        position='top'
      />
    </>
  )
}
