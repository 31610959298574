import { IonIcon, IonModal, IonToast } from '@ionic/react'
import { closeCircleOutline } from 'ionicons/icons'
import { colors } from '../../../theme/colors'
import { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../auth'
import { limitDecimalPlaces } from '../../../utils'
import axios from 'axios'
import GetLocationDataBasedOnLocationId from '../../../Utils/GetLocationDataBasedOnLocationId'
import CommonHeader from '../../../commonComponents/CommonHeader'
import CancelAppointmentForm from '../../details/CancelAppointmentForm'

export default function CancelAppointment({ appointment, getAppointment }) {
  const [modalOpen, setModalOpen] = useState(false)
  const [defaultChargeEnabled, setDefaultChargeEnabled] = useState(false)
  const [customChargeValue, setCustomChargeValue] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [cardOnFile, setCardOnFile] = useState(false)
  const { locationData, locationId, getBusinessData } = useContext(AuthContext)
  const selectedLocationData = GetLocationDataBasedOnLocationId(locationData, locationId)
  const isCustomerSubscribed = appointment?.customChargeValue?.isSubscribed
  const locationCancelationSettings = isCustomerSubscribed
    ? selectedLocationData?.appointmentSettings?.cancellationSettings?.subscriber
    : selectedLocationData?.appointmentSettings?.cancellationSettings?.nonSubscriber
  const [toast, setToast] = useState({ isOpen: false, message: '', color: 'success' })

  const currentTime = new Date().getTime()
  const timeDifferenceInHours = (appointment?.startTime - currentTime) / (60 * 60 * 1000)

  const cancellationAllowed = timeDifferenceInHours > 24
  const handleCustomChargeValue = (inputValue) => {
    setCustomChargeValue(inputValue)

    if (parseFloat(inputValue) > appointment?.payment?.totalPrice) {
      setError('Charge value cannot be more than appointment total price')
    } else {
      setError('')
    }
  }

  const getCancellationCharges = () => {
    // Assuming appointment.payment.totalprice is the total price
    let totalPrice = appointment?.payment?.totalPrice
    let defaultCancellationCharges = 0
    // Check the type property and apply discount accordingly
    if (locationCancelationSettings?.type === 'fixed') {
      // Subtract the fixed value
      defaultCancellationCharges = locationCancelationSettings?.value
    } else if (locationCancelationSettings?.type === 'percentage') {
      // Subtract the percentage
      const percentage = locationCancelationSettings?.value / 100
      defaultCancellationCharges = totalPrice * percentage
    }
    return defaultCancellationCharges
  }
  const defaultCancellationCharges = getCancellationCharges()

  const handleCancelAppointment = async () => {
    setLoading(true)
    let cancelationCharge = 0
    let customizedCancelationCharge = false
    // Determine if customized cancellation charge should be applied
    if (locationCancelationSettings?.charge && cardOnFile && !cancellationAllowed && appointment?.agreedToCancelPolicy) {
      cancelationCharge = parseFloat(defaultChargeEnabled ? defaultCancellationCharges : customChargeValue).toFixed(2)
      customizedCancelationCharge = !defaultChargeEnabled
    }

    try {
      const input = {
        appointmentId: appointment?._id,
        status: 'Canceled',
        paidExternal: true,
        cancelationCharge,
        customizedCancelationCharge,
      }

      await axios.patch(`/appointment_v2/updateStatus?id=${appointment?._id}`, input)

      const paymentObjectWithCancelationCharge = {
        ...appointment?.payment,
        cancelationCharge,
      }

      await axios.patch(`/appointment_v2?id=${appointment?._id}`, {
        payment: paymentObjectWithCancelationCharge,
      })
      getAppointment()
      setToast({ isOpen: true, message: 'Appointment canceled successfully!', color: 'success' })

      closeModal()
    } catch (error) {
      // Handle error
      setToast({ isOpen: true, message: 'Error canceling appointment', color: 'danger' })
    }

    setLoading(false)
  }
  useEffect(() => {
    let formattedValue = limitDecimalPlaces(customChargeValue, 2)
    setCustomChargeValue(formattedValue)
  }, [customChargeValue])
  const openModal = () => {
    setModalOpen(true)
    getBusinessData()
  }
  const getCard = async () => {
    try {
      let response = await axios.get(`/customer_v2/cardStripe?customerId=${appointment?.customer?._id}`)

      setCardOnFile(response?.data?.cardOnFile || false)
    } catch (error) {
      console.error('Error fetching card information:', error)
    }
  }

  useEffect(() => {
    getCard()
  }, [])

  const closeModal = () => {
    getBusinessData()
    setDefaultChargeEnabled(false)
    setCustomChargeValue(false)
    setError(false)
    setLoading(false)
    setTimeout(() => {
      setModalOpen(false)
    }, 100)
  }
  const toggleDefaultCharge = () => {
    setDefaultChargeEnabled(!defaultChargeEnabled)
    setCustomChargeValue('')
    setError('')
  }

  return (
    <>
      <div
        onClick={openModal}
        style={{
          flex: '1 1 0',
          padding: 8,
          background: '#EBEBEB',
          borderRadius: 12,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 8,
          display: 'inline-flex',
        }}
      >
        <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 4, display: 'flex' }}>
          <div style={{ width: 24, height: 24, position: 'relative' }}>
            <IonIcon style={{ height: 30, width: 30, marginBottom: '0.5rem', color: colors.black70 }} icon={closeCircleOutline} />
          </div>
          <div style={{ color: '#525252', fontSize: 16, fontFamily: 'Figtree', fontWeight: '600', lineHeight: '24px', wordWrap: 'break-word' }}>Cancel</div>
        </div>
      </div>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        duration={2000}
        color={toast.color}
      />
      <IonModal isOpen={modalOpen} onDidDismiss={closeModal}>
        <CommonHeader title={'Cancel Appointment'} closeButton={true} closeModal={closeModal}></CommonHeader>
        {/* if customer doesnot have card on file,or if cancellation is allowed or if the settings are not enabled then show a default popup. */}

        <CancelAppointmentForm
          locationCancelationSettings={locationCancelationSettings}
          cardOnFile={cardOnFile}
          defaultCancellationCharges={defaultCancellationCharges}
          defaultChargeEnabled={defaultChargeEnabled}
          toggleDefaultCharge={toggleDefaultCharge}
          appointment={appointment}
          customChargeValue={customChargeValue}
          handleCustomChargeValue={handleCustomChargeValue}
          error={error}
          closeModal={closeModal}
          loading={loading}
          handleCancelAppointment={handleCancelAppointment}
        />
      </IonModal>
    </>
  )
}
