import { useState } from 'react'
import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonRow,
  IonToast,
} from '@ionic/react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import StandardContainer from '../commonComponents/StandardContainer'

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {
  const [isDisabled, setIsDisabled] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [role, setRole] = useState('')
  const history = useHistory()

  // Toast state
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    color: 'success',
  })

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  const createButtonClicked = async () => {
    setIsDisabled(true)
    let locData = locationId ? { locations: [locationId] } : {}
    try {
      let response = await axios.post(`/employee`, {
        firstName,
        lastName,
        email,
        businessId,
        phoneNumber,
        role,
        ...locData,
      })
      if (response.status === 200) {
        history.push(`employees/${response.data._id}/details`)
        close()
        showToastMessage('Employee created successfully!')
      }
    } catch (error) {
      showToastMessage('Error creating employee. Please try again.', 'danger')
      setIsDisabled(false)
    }
  }

  const close = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setPhoneNumber('')
    setRole('')
    setIsDisabled(false)
    handleClose()
  }

  return (
    <IonModal isOpen={open} onDidDismiss={handleClose}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Add Employee</IonTitle>
          <IonButton fill='none' slot='end' onClick={handleClose}>
            Close
          </IonButton>
        </IonToolbar>
      </IonHeader>
      <IonContent padding>
        <StandardContainer>
          <IonItem>
            <IonLabel position='stacked'>Role</IonLabel>
            <IonSelect value={role} placeholder='Select Role' onIonChange={(e) => setRole(e.detail.value)}>
              <IonSelectOption value='Barber'>Professional</IonSelectOption>
              <IonSelectOption value='Receptionist'>Receptionist</IonSelectOption>
              <IonSelectOption value='Office'>Office</IonSelectOption>
            </IonSelect>
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>First Name</IonLabel>
            <IonInput value={firstName} onIonInput={(e) => setFirstName(e.detail.value)} placeholder='First Name' required />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Last Name</IonLabel>
            <IonInput value={lastName} onIonInput={(e) => setLastName(e.detail.value)} placeholder='Last Name' required />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Email</IonLabel>
            <IonInput type='email' value={email} onIonInput={(e) => setEmail(e.detail.value)} placeholder='Email' required />
          </IonItem>
          <IonItem>
            <IonLabel position='stacked'>Phone Number</IonLabel>
            <IonInput value={phoneNumber} onIonInput={(e) => setPhoneNumber(e.detail.value)} placeholder='Phone Number' required />
          </IonItem>
          <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <IonButton disabled={isDisabled} color='primary' onClick={createButtonClicked}>
              Create
            </IonButton>
            <IonButton color='medium' onClick={close}>
              Cancel
            </IonButton>
          </IonRow>
        </StandardContainer>
      </IonContent>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonModal>
  )
}
