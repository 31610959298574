import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar, IonToast } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'

export default function Details({ match }) {
  const service_id = match?.params?.id
  const [service, setService] = useState()
  const [serviceTemp, setServiceTemp] = useState()
  const [serviceChanged, setServiceChanged] = useState(false)
  const [locations, setLocations] = useState()
  const [subscriptionPackages, setSubscriptionPackages] = useState()
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const getService = async () => {
    try {
      // Fetch the service details using the service ID
      let response = await axios.get(`/service?id=${service_id}`)

      // Update state with the fetched service data
      setService(response.data)
      setServiceTemp(response.data)
    } catch (error) {
      console.error('Error fetching service details:', error) // Log the error for debugging
      // Optionally, handle the error (e.g., set an error state or display an error message in the UI)
    } finally {
    }
  }

  useEffect(() => {
    getService()
  }, [service_id])

  const getLocationsList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)
      setLocations(response.data)
    } catch (error) {
      console.error('Failed to load locations.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const getSubscriptionPackagesList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
      setSubscriptionPackages(response.data)
    } catch (error) {
      console.error('Failed to load subscription packages.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getSubscriptionPackagesList()
  }, [businessData])

  const updateService = (value) => {
    setServiceTemp(value)
    setServiceChanged(true)
  }

  const save = async () => {
    try {
      await axios.patch(`/service?id=${service_id}`, serviceTemp)
      await getService()
      setServiceChanged(false)
      showToastMessage('Service updated successfully!', 'success')
    } catch (error) {
      showToastMessage('Failed to update service.', 'danger')
    }
  }

  const cancel = () => {
    setServiceTemp(service)
    setServiceChanged(false)
  }

  const deleteService = async () => {
    if (window.confirm('Are you sure you want to delete the service?')) {
      try {
        await axios.delete(`/service?id=${service_id}`)
        showToastMessage('Service deleted successfully!', 'success')
        history.goBack()
      } catch (error) {
        showToastMessage('Failed to delete service.', 'danger')
      }
    }
  }

  const showToastMessage = (message, color = 'success') => {
    setToast({ isOpen: true, message, color })
  }

  if (!service) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
          <IonButtons slot='start'>
            <IonBackButton />
          </IonButtons>
          <IonTitle>{serviceTemp?.name || 'loading...'}</IonTitle>
          {serviceChanged ? (
            <>
              <IonButtons slot='end'>
                <IonButton onClick={cancel}>Cancel</IonButton>
              </IonButtons>
              <IonButtons slot='end'>
                <IonButton color='primary' onClick={save}>
                  Save
                </IonButton>
              </IonButtons>
            </>
          ) : null}
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <StandardContainer>
          <IonCard style={{ padding: 20 }}>
            <GeneralDetails
              service={serviceTemp}
              updateService={updateService}
              locations={locations}
              subscriptionPackages={subscriptionPackages}
              locationId={locationId}
            />
          </IonCard>
          <IonCard style={{ padding: 20 }}>
            <IonButton color='warning' expand='full' onClick={deleteService}>
              Delete
            </IonButton>
          </IonCard>
        </StandardContainer>
      </IonContent>

      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </IonPage>
  )
}
