import { useState, useEffect, useContext } from 'react'
import GeneralDetails from './details_general'
import axios from 'axios'
import { AuthContext } from '../auth'
import { useHistory } from 'react-router-dom'
import { IonBackButton, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar, IonToast } from '@ionic/react'
import StandardContainer from '../commonComponents/StandardContainer'

export default function Details({ match }) {
  const product_id = match?.params?.id
  const [product, setProduct] = useState()
  const [productTemp, setProductTemp] = useState()
  const [productChanged, setProductChanged] = useState(false)
  const [locations, setLocations] = useState()
  const [subscriptionPackages, setSubscriptionPackages] = useState()
  const { businessData, locationId } = useContext(AuthContext)
  const history = useHistory()

  // Toast state management
  const [toast, setToast] = useState({ isOpen: false, message: '', color: '' })

  const showToastMessage = (message, color = 'danger') => {
    setToast({ isOpen: true, message, color })
  }

  const getProduct = async () => {
    try {
      const response = await axios.get(`/product?id=${product_id}`)
      setProduct(response.data) // Update state with product data
      setProductTemp(response.data) // Set temporary product state
    } catch (error) {
      console.error('Failed to load product details:', error) // Log the error for debugging
    } finally {
    }
  }

  useEffect(() => {
    getProduct()
  }, [product_id])

  const getLocationsList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/location_v2/getList?businessId=${businessData._id}`)
      setLocations(response.data)
    } catch (error) {
      console.error('Failed to load locations.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getLocationsList()
  }, [businessData])

  const getSubscriptionPackagesList = async () => {
    if (!businessData?._id) return
    try {
      let response = await axios.get(`/subscriptionPackage/getList?businessId=${businessData._id}`)
      setSubscriptionPackages(response.data)
    } catch (error) {
      console.error('Failed to load subscription packages.', error) // Log the error for debugging
    }
  }

  useEffect(() => {
    getSubscriptionPackagesList()
  }, [businessData])

  const updateProduct = (value) => {
    setProductTemp(value)
    setProductChanged(true)
  }

  const save = async () => {
    try {
      let response = await axios.patch(`/product?id=${product_id}`, productTemp)
      await getProduct()
      setProductChanged(false)
      showToastMessage('Product updated successfully!', 'success') // Success toast
    } catch (error) {
      showToastMessage('Failed to update product.', 'danger') // Error toast
    }
  }

  const cancel = () => {
    setProductTemp(product)
    setProductChanged(false)
  }

  const deleteProduct = async () => {
    if (window.confirm('Are you sure you want to delete the product?')) {
      try {
        await axios.delete(`/product?id=${product_id}`)
        history.push(-1)
        showToastMessage('Product deleted successfully!', 'success') // Success toast
      } catch (error) {
        showToastMessage('Failed to delete product.', 'danger') // Error toast
      }
    }
  }

  if (!product) {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSpinner name='dots' />
        </IonContent>
      </IonPage>
    )
  }

  return (
    <>
      <IonPage>
        <IonHeader>
          <IonToolbar color='black' style={{ backgroundColor: 'white', color: 'black' }}>
            <IonButtons slot='start'>
              <IonBackButton />
            </IonButtons>
            <IonTitle>{productTemp?.name || 'loading...'}</IonTitle>
            {productChanged ? (
              <>
                <IonButtons slot='end'>
                  <IonButton onClick={cancel}>Cancel</IonButton>
                </IonButtons>
                <IonButtons slot='end'>
                  <IonButton color='primary' onClick={save}>
                    Save
                  </IonButton>
                </IonButtons>
              </>
            ) : null}
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <StandardContainer>
            <IonCard style={{ padding: 20 }}>
              <GeneralDetails
                product={productTemp}
                updateProduct={updateProduct}
                locations={locations}
                subscriptionPackages={subscriptionPackages}
                locationId={locationId}
              />
            </IonCard>
            <IonCard style={{ padding: 20 }}>
              <IonButton color='warning' variant='contained' onClick={deleteProduct}>
                Delete
              </IonButton>
            </IonCard>
          </StandardContainer>
        </IonContent>
      </IonPage>
      <IonToast
        position='top'
        isOpen={toast.isOpen}
        onDidDismiss={() => setToast({ ...toast, isOpen: false })}
        message={toast.message}
        color={toast.color}
        duration={2000}
      />
    </>
  )
}
