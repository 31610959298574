import * as React from 'react';
import {Button, FormControl, InputLabel, MenuItem, Select, IconButton} from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios'
import { useHistory } from 'react-router-dom';
// import AppointmentScheduler from '../scheduleAnAppointment/stepController'
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export default function CreateTestDialog({ open, handleClose, businessId, locationId }) {

  return (

    <Dialog open={open} onClose={handleClose} fullScreen TransitionComponent={Transition}>
      <DialogActions>
        <IconButton onClick={handleClose}  size="large" color="primary">
          <CloseIcon fontSize="inherit"/>
        </IconButton>
      </DialogActions>
      <DialogContent>
        {/* <div style={{width:500, height:1}}></div> */}
      {/* <AppointmentScheduler noContainer businessId={businessId} locationId={locationId}/> */}
        
      </DialogContent>
      
    </Dialog>
  );
}