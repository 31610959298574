import { IonInput, IonItem, IonLabel, IonGrid, IonRow, IonCol } from '@ionic/react'
import LocationsMultiSelect from './locationsMultiSelect'

export default function DetailsView({ product, updateProduct, checkProduct, locations, subscriptionPackages, locationId }) {
  const onValueChange = (field, value) => {
    updateProduct({ ...product, [field]: value })
  }

  if (!product) return 'loading...'

  return (
    <>
      <IonGrid>
        <IonRow>
          <IonCol size='12' size-md='6'>
            {/* Name Field */}
            <IonItem>
              <IonLabel position='stacked'>Name</IonLabel>
              <IonInput value={product.name} onIonInput={(e) => onValueChange('name', e.detail.value)} type='text' />
            </IonItem>

            {/* Description Field */}
            <IonItem>
              <IonLabel position='stacked'>Description</IonLabel>
              <IonInput value={product.description} onIonInput={(e) => onValueChange('description', e.detail.value)} type='text' />
            </IonItem>

            {/* Price Field */}
            <IonItem>
              <IonLabel position='stacked'>Amount</IonLabel>
              <IonInput value={product.price} onIonInput={(e) => onValueChange('price', e.detail.value)} type='number' inputmode='decimal' />
            </IonItem>
          </IonCol>

          <IonCol size='12' size-md='6'>
            {/* Locations Multi-Select */}
            <LocationsMultiSelect locations={locations} productLocations={product.locations} onValueChange={onValueChange} locationId={locationId} />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  )
}
