import React, { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { formatDateToISOString, getFormattedMediumDate } from '../../utils'
import {
  IonCard,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonRow,
  IonToggle,
  IonSelect,
  IonSelectOption,
  IonDatetimeButton,
  IonDatetime,
  IonIcon,
} from '@ionic/react'
import CommonHeader from '../../commonComponents/CommonHeader'
import StandardContainer from '../../commonComponents/StandardContainer'
import TimeSelector from '../details/TimeSelector'
import { colors } from '../../theme/colors'
import { calendarOutline } from 'ionicons/icons'

function getDefaultData() {
  return {
    dateText: getFormattedMediumDate(new Date()),
    dateEpoch: new Date().getTime(),
    enabled: false,
    startTime: null,
    endTime: null,
    employeeId: '',
  }
}

export default function AddBreak({ selectedDate, employees, onClose, open, handleSave, standardSchedule }) {
  const [data, setData] = useState(getDefaultData())
  const [errorMessage, setErrorMessage] = useState('error')
  const [isSaving, setIsSaving] = useState(false)
  const [repeatBreak, setRepeatBreak] = useState(false)
  const [breakType, setBreakType] = useState('daily')
  const [breakInterval, setBreakInterval] = useState('')
  useEffect(() => {
    setData(getDefaultData())
    setIsSaving(false)
    setErrorMessage('')
    setRepeatBreak(false)
    setBreakType('daily')
    setBreakInterval('')
  }, [open])
  const toggleRepeat = (value) => {
    setRepeatBreak(value)
  }
  const handleBreakType = (value) => {
    setBreakType(value)
  }
  const getDateBasedDay = (dateStr) => {
    const date = new Date(dateStr)
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const dayIndex = date.getDay()
    const day = days[dayIndex]
    return day
  }

  const save = () => {
    setErrorMessage('')

    if (!data.startTime || !data.endTime) {
      setErrorMessage('**Start time and End time must be filled out')
      return
    }
    if (!data.employeeId) {
      setErrorMessage('**Please select an employee')
      return
    }
    let newData = { ...data }
    newData.repeat = {
      enabled: repeatBreak,
      type: breakType, //"daily"
      interval: 1,
      occurrences: parseFloat(breakInterval),
    }
    if (breakType == 'weekly') {
      newData.repeat.day = getDateBasedDay(data.dateText)
    }
    setIsSaving(true)
    handleSave(newData)
  }

  const handleClose = () => {
    onClose()
  }

  const handleListItemClick = (value, d) => {
    onClose(value)
  }

  function handleChange(id, fieldKey, newValue) {
    setData({ ...data, ...{ [fieldKey]: newValue } })
  }
  const changeInterval = (value) => {
    setBreakInterval(value)
  }
  function updateDate(id, date) {
    setData({
      ...data,
      ...{ dateText: getFormattedMediumDate(date), date: date, dateEpoch: date.getTime() },
    })
  }
  const isSaveDisabled = isSaving || !data.employeeId || !data.startTime || !data.endTime || (repeatBreak ? !breakType || !breakInterval : false)

  return (
    <>
      <IonModal isOpen={open} onDidDismiss={handleClose}>
        <CommonHeader
          saveButton={true}
          saveDisabled={isSaveDisabled}
          handleSaveButton={save}
          title={'Add a break'}
          closeButton={true}
          closeModal={handleClose}
        />
        <IonContent>
          <StandardContainer>
            <IonCard style={{ padding: '2rem' }}>
              <IonGrid>
                <IonRow
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonCol>
                    <h6>Employee</h6>
                  </IonCol>
                  <IonCol>
                    <IonSelect value={data.employeeId} placeholder='Select Employee' onIonChange={(e) => handleChange(data._id, 'employeeId', e.detail.value)}>
                      {employees.map((employee) => (
                        <IonSelectOption key={employee.id} value={employee.id}>
                          {employee.title}
                        </IonSelectOption>
                      ))}
                    </IonSelect>
                  </IonCol>
                </IonRow>
                <IonRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <IonCol>
                    <h6>Date</h6>
                  </IonCol>
                  <IonCol>
                    <CalendarSelect dateText={data.dateText} id={data._id} updateDate={updateDate} />
                  </IonCol>
                </IonRow>
                <IonRow
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonCol>
                    <h6>Time</h6>
                  </IonCol>
                  <IonCol style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <TimeSelector dayKey={data._id} fieldKey='startTime' value={data.startTime} handleChange={handleChange} useFifteenMin={true} />
                    <TimeSelector dayKey={data._id} fieldKey='endTime' value={data.endTime} handleChange={handleChange} useFifteenMin={true} />
                  </IonCol>
                </IonRow>
                <IonRow
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <IonCol>
                    <h6>Repeat Breaks</h6>
                  </IonCol>
                  <IonCol>
                    <IonToggle
                      justify='space-between'
                      size='small'
                      checked={repeatBreak}
                      onIonChange={(e) => {
                        toggleRepeat(e.detail.checked)
                      }}
                    />
                  </IonCol>
                </IonRow>
                {repeatBreak && (
                  <>
                    <IonRow>
                      <IonCol>
                        <h6>Type</h6>
                      </IonCol>
                      <IonCol style={{ marginLeft: '1rem' }}>
                        <IonRadioGroup style={{ display: 'flex', alignItems: 'center' }} value={breakType}>
                          <div style={{ display: 'flex', alignItems: 'center', margin: '1rem 0' }}>
                            <IonRadio
                              key='daily'
                              slot='start'
                              mode='md'
                              checked={breakType === 'daily' ? true : false}
                              onClick={() => handleBreakType('daily')}
                            ></IonRadio>

                            <IonLabel
                              onClick={() => handleBreakType('daily')}
                              style={{
                                color: breakType === 'daily' ? colors.primaryBlue : '',
                                marginLeft: '0.5rem',
                              }}
                            >
                              Daily
                            </IonLabel>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', margin: '1rem' }}>
                            <IonRadio
                              key='weekly'
                              mode='md'
                              slot='start'
                              checked={breakType === 'weekly' ? true : false}
                              onClick={() => handleBreakType('weekly')}
                            ></IonRadio>

                            <IonLabel
                              onClick={() => handleBreakType('weekly')}
                              style={{
                                color: breakType === 'weekly' ? colors.primaryBlue : '',
                                marginLeft: '0.5rem',
                              }}
                            >
                              Weekly
                            </IonLabel>
                          </div>
                        </IonRadioGroup>
                      </IonCol>
                    </IonRow>
                    <IonRow>
                      <IonCol>
                        <h6>Interval</h6>
                      </IonCol>
                      <IonCol>
                        <IonItem>
                          <IonInput
                            min={0}
                            type='number'
                            // disabled={testingEmail}
                            onIonInput={(e) => {
                              changeInterval(e.detail.value)
                            }}
                            value={breakInterval}
                            placeholder='Interval'
                          ></IonInput>
                        </IonItem>
                      </IonCol>
                    </IonRow>
                  </>
                )}

                {errorMessage && (
                  <IonRow>
                    <IonCol>
                      <p style={{ color: 'red' }}>{errorMessage}</p>
                    </IonCol>
                  </IonRow>
                )}
              </IonGrid>
            </IonCard>
          </StandardContainer>
        </IonContent>
      </IonModal>
    </>
  )
}

function CalendarSelect({ dateText, updateDate, id }) {
  const [value, setValue] = React.useState(dayjs(dateText))

  const handleChange = (newValue) => {
    updateDate(id, new Date(newValue))
    setValue(new Date(newValue))
  }

  return (
    <>
      <IonDatetimeButton datetime='datetime2'></IonDatetimeButton>
      <IonModal keepContentsMounted={true}>
        <IonDatetime
          id='datetime2'
          presentation='date'
          value={formatDateToISOString(new Date(value))}
          onIonChange={(e) => {
            handleChange(e.detail.value)
          }}
          formatOptions={{
            date: {
              weekday: 'short',
              month: 'long',
              day: '2-digit',
            },
          }}
        ></IonDatetime>
      </IonModal>{' '}
      {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker size='small' inputFormat='MM/DD/YYYY' value={dayjs(value)} onChange={handleChange} />
      </LocalizationProvider> */}
    </>
  )
}
